<template>
  <v-app>
    <v-app-bar app >
      <div class="d-flex justify-space-between align-center" style="width:100%">
        
        <div class="d-flex align-center">
          <router-link class="d-flex align-center" to="/">
            <v-img width="38" contain src="@/assets/images/logo.png"></v-img>
            <div>
              <!-- <div  v-if="localeLang == 'en'">
                <div class="text-h6 text-sm-h5 font-weight-medium ml-2">Life Sciences Cells Bank</div>
                <div class="text-h6 text-sm-h5 font-weight-medium ml-2">Foundation</div>
              </div> -->
              <div class="text-h6 text-sm-h5 font-weight-medium ml-2">{{ $t('AppBar.logotext') }}</div>
            </div>
          </router-link>
        </div>

        <div class="d-none d-sm-flex justify-start">
          <v-menu open-on-hover min-width="180" content-class="nav-menu" offset-y  max-height="auto">
            <template v-slot:activator="{ on, attrs }">
              <div class="nav-menu-text white--text px-5" v-bind="attrs" v-on="on">{{ $t('AppBar.Menu.About') }}</div>
            </template>  
            <v-list class="nav-menu-list">
              <v-list-item to="/foundation/supercells#" class="text-body-2">{{ $t('AppBar.SubMenu.WhoAmI') }}</v-list-item>
              <v-list-item to="/foundation/supercells#value" class="text-body-2 text-capitalize">{{ $t('AppBar.SubMenu.value') }}</v-list-item>
              <v-list-item to="/foundation/supercells#safety" class="text-body-2">{{ $t('AppBar.SubMenu.Sr') }}</v-list-item>
              <v-list-item to="/foundation/supercells#team" class="text-body-2">{{ $t('AppBar.SubMenu.Team') }}</v-list-item>
              <v-list-item v-if="newsil8n=='ja'" target="_blanck" href="/SuperCells3.0—JP.pdf" class="text-body-2">{{ $t('AppBar.SubMenu.WhitePaper') }}</v-list-item>
              <v-list-item v-else-if="newsil8n=='ko'" target="_blanck" href="/SuperCells3.0—KR.pdf" class="text-body-2">{{ $t('AppBar.SubMenu.WhitePaper') }}</v-list-item>
              <v-list-item v-else href="/SuperCells3.0—EN.pdf" target="_blanck" class="text-body-2">{{ $t('AppBar.SubMenu.WhitePaper') }}</v-list-item>
            </v-list>
          </v-menu> 
          <v-menu open-on-hover min-width="180" offset-y content-class="nav-menu" max-height="auto">
            <template v-slot:activator="{ on, attrs }">
              <div class="nav-menu-text white--text px-5" v-bind="attrs" v-on="on">{{ $t('AppBar.Menu.Menu3') }}</div>
            </template>  
            <v-list class="nav-menu-list">
              <v-list-item to="/detail" class="text-body-2">{{ $t('AppBar.Menu3.SubMenu1') }}</v-list-item>
              <v-list-item to="/foundation/economics" class="text-body-2 text-capitalize">{{ $t('AppBar.SubMenu.TokenEconomics') }}</v-list-item>
              <v-list-item to="/govern" class="text-body-2">{{ $t('AppBar.SubMenu.Govern') }}</v-list-item>
              <!-- <v-list-item to="/foundation/ico" class="text-body-2 text-capitalize">{{ $t('AppBar.SubMenu.Project') }}</v-list-item> -->
              <v-list-item href="https://dapp.supercells.pro" target="_blank" class="text-body-2">{{ $t('AppBar.SubMenu.Purchase') }}</v-list-item>
            </v-list>
          </v-menu> 

          <v-menu open-on-hover min-width="180" offset-y content-class="nav-menu" max-height="auto">
            <template v-slot:activator="{ on, attrs }">
              <div class="nav-menu-text white--text px-5" v-bind="attrs" v-on="on">{{ $t('AppBar.Menu.Ecology') }}</div>
            </template>  
            <v-list class="nav-menu-list">
              <v-list-item href="https://supercells.vip#product" target="_blank" class="text-body-2">{{ $t('AppBar.SubMenu.Product') }}</v-list-item>
              <v-list-item to="/ecology/chain" class="text-body-2">{{ $t('AppBar.SubMenu.Chain') }}</v-list-item>
              <v-list-item href="https://dapp.supercells.pro/swap" target="_blank" class="text-body-2">{{ $t('AppBar.SubMenu.Foundation') }}</v-list-item>
              <v-list-item to="/ecology/scwallet" class="text-body-2">{{ $t('AppBar.SubMenu.Wallet') }}</v-list-item>
              <v-list-item to="/ecology/community/interaction" class="text-body-2">{{ $t('AppBar.SubMenu.Comm') }}</v-list-item>
            </v-list>
          </v-menu> 
          <router-link to="/road" class="d-block white--text px-5">{{ $t('AppBar.Menu.Roadmap') }}</router-link>
          <router-link to="/contact-us" class="d-block white--text px-5">{{ $t('AppBar.Menu.Contact') }}</router-link>
          <a href="https://dapp.supercells.pro" target="_blank" class="d-block white--text px-5">DAPP</a>
          <a v-if="newsil8n=='zh'" href="https://docs.supercells.vip/cn" target="_blank" class="d-block px-5">DOCS</a>
          <a v-if="newsil8n=='en'" href="https://docs.supercells.vip/en" target="_blank" class="d-block px-5">DOCS</a>
          <a v-if="newsil8n=='ja'" href="https://docs.supercells.vip/jp" target="_blank" class="d-block px-5">DOCS</a>

          <v-menu open-on-hover min-width="120" offset-y auto transition="scale-transition" close-delay="200">
            <template v-slot:activator="{ on, attrs }">
              <div class="d-flex justify-start align-center px-5" v-bind="attrs" v-on="on" style="cursor: pointer">
                <img width="22" src="@/assets/images/en.svg" v-if="$i18n.locale == 'en'" />
                <img width="22" src="@/assets/images/ja.svg" v-else-if="$i18n.locale == 'ja'" />
                <img width="22" src="@/assets/images/ko.png" v-else-if="$i18n.locale == 'ko'" />
                <img width="22" src="@/assets/images/zh.svg" v-else />
              </div>
            </template>  
            <v-list class="nav-menu-list pa-0">
              <!-- <v-list-item @click="selectLanguage('zh')">
                <div class="d-flex justify-start align-center">
                  <img width="22" src="@/assets/images/zh.svg" />
                  <div class="ml-2 text-body-2">简体中文</div>
                </div>
              </v-list-item> -->
              <v-list-item @click="selectLanguage('en')">
                <div class="d-flex justify-start align-center">
                  <img width="22" src="@/assets/images/en.svg" />
                  <div class="ml-2 text-body-2">English</div>
                </div>
              </v-list-item>
              <v-list-item @click="selectLanguage('ja')">
                <div class="d-flex justify-start align-center">
                  <img width="22" src="@/assets/images/ja.svg" />
                  <div class="ml-2 text-body-2">日本語</div>
                </div>
              </v-list-item>
              <v-list-item @click="selectLanguage('ko')">
                <div class="d-flex justify-start align-center">
                  <img width="22" src="@/assets/images/ko.png" />
                  <div class="ml-2 text-body-2">한국어</div>
                </div>
              </v-list-item>
            </v-list>
          </v-menu> 
        </div>


        <div class="d-block d-sm-none">

          <div class="d-flex justify-start align-center">
            <v-menu min-width="120" offset-y auto transition="scale-transition" close-delay="200">
              <template v-slot:activator="{ on, attrs }">
                <div class="d-flex justify-start align-center" v-bind="attrs" v-on="on" style="cursor: pointer;height:62px;">
                  <img width="22" src="@/assets/images/en.svg" v-if="$i18n.locale == 'en'" />
                  <img width="22" src="@/assets/images/ja.svg" v-else-if="$i18n.locale == 'ja'" />
                  <img width="22" src="@/assets/images/zh.svg" v-else />
                  <!-- <div class="ml-1">{{ $t($i18n.locale) }}</div> -->
                </div>
              </template>  
              <v-list class="pa-0">
                <!-- <v-list-item @click="selectLanguage('zh')">
                  <div class="d-flex justify-start align-center">
                    <img width="22" src="@/assets/images/zh.svg" />
                    <div class="ml-2 text-body-2">简体中文</div>
                  </div>
                </v-list-item> -->
                <v-list-item @click="selectLanguage('en')">
                  <div class="d-flex justify-start align-center">
                    <img width="22" src="@/assets/images/en.svg" />
                    <div class="ml-2 text-body-2">English</div>
                  </div>
                </v-list-item>
                <v-list-item @click="selectLanguage('ja')">
                  <div class="d-flex justify-start align-center">
                    <img width="22" src="@/assets/images/ja.svg" />
                    <div class="ml-2 text-body-2">日本語</div>
                  </div>
                </v-list-item>
              </v-list>
            </v-menu> 
            <v-btn icon @click="navigationDrawer = !navigationDrawer" class="ml-2">
              <v-icon color="#AC8250">mdi-menu</v-icon>
            </v-btn>  
          </div>

          <v-navigation-drawer hide-overlay temporary fixed right width="70%" height="100vh" v-model="navigationDrawer" class="nav-drawer">
            <div class="px-5 py-10">
              <div>
                
                <div>
                  <div>{{ $t('AppBar.Menu.About') }}</div>
                  <div class="pl-5">
                    <router-link to="/foundation/supercells#" class="d-flex justify-space-between align-center mt-1">
                      <div class="text-body-2">{{ $t('AppBar.SubMenu.WhoAmI') }}</div>
                      <v-icon>mdi-chevron-right</v-icon>
                    </router-link>
                    <router-link to="/foundation/supercells#value-m" class="d-flex justify-space-between align-center mt-1">
                      <div class="text-body-2">{{ $t('AppBar.SubMenu.value') }}</div>
                      <v-icon>mdi-chevron-right</v-icon>
                    </router-link>
                    <router-link to="/foundation/supercells#safety2" class="d-flex justify-space-between align-center mt-1">
                      <div class="text-body-2">{{ $t('AppBar.SubMenu.Sr') }}</div>
                      <v-icon>mdi-chevron-right</v-icon>
                    </router-link>
                    <router-link to="/foundation/supercells#team" class="d-flex justify-space-between align-center mt-1">
                      <div class="text-body-2">{{ $t('AppBar.SubMenu.Team') }}</div>
                      <v-icon>mdi-chevron-right</v-icon>
                    </router-link>
                    <a v-if="newsil8n=='ja'" href="/SuperCells3.0—JP.pdf" target="_blanck" class="d-flex justify-space-between align-center mt-1">
                      <div class="text-body-2">{{ $t('AppBar.SubMenu.WhitePaper') }}</div>
                      <v-icon>mdi-chevron-right</v-icon>
                    </a>
                    <a v-else-if="newsil8n=='ko'" href="/SuperCells3.0—KR.pdf" target="_blanck" class="d-flex justify-space-between align-center mt-1">
                      <div class="text-body-2">{{ $t('AppBar.SubMenu.WhitePaper') }}</div>
                      <v-icon>mdi-chevron-right</v-icon>
                    </a>
                    <a v-else href="/SuperCells3.0—EN.pdf" target="_blanck" class="d-flex justify-space-between align-center mt-1">
                      <div class="text-body-2">{{ $t('AppBar.SubMenu.WhitePaper') }}</div>
                      <v-icon>mdi-chevron-right</v-icon>
                    </a>
                  </div>
                </div>

                <div class="mt-4">
                  <div>{{ $t('AppBar.Menu.Menu3') }}</div>
                  <div class="pl-5">
                    <router-link to="/detail" class="d-flex justify-space-between align-center mt-1">
                      <div class="text-body-2">{{ $t('AppBar.Menu3.SubMenu1') }}</div>
                      <v-icon>mdi-chevron-right</v-icon>
                    </router-link>
                    <router-link to="/foundation/economics" class="d-flex justify-space-between align-center mt-1">
                      <div class="text-body-2">{{ $t('AppBar.SubMenu.TokenEconomics') }}</div>
                      <v-icon>mdi-chevron-right</v-icon>
                    </router-link>
                    <router-link to="/govern" class="d-flex justify-space-between align-center mt-1">
                      <div class="text-body-2">{{ $t('AppBar.SubMenu.Govern') }}</div>
                      <v-icon>mdi-chevron-right</v-icon>
                    </router-link>
                    <!-- <router-link to="/foundation/ico" class="d-flex justify-space-between align-center mt-1">
                      <div class="text-body-2">{{ $t('AppBar.SubMenu.Project') }}</div>
                      <v-icon>mdi-chevron-right</v-icon>
                    </router-link> -->
                    <a target="_blank" href="https://dapp.supercells.pro" class="d-flex justify-space-between align-center mt-1">
                      <div class="text-body-2">{{ $t('AppBar.SubMenu.Purchase') }}</div>
                      <v-icon>mdi-chevron-right</v-icon>
                    </a>
                  </div>
                </div>

                <div class="mt-4">
                  <div>{{ $t('AppBar.Menu.Ecology') }}</div>
                  <div class="pl-5">
                    <a href="https://supercells.pro#product" target="_blank" class="d-flex justify-space-between align-center mt-1">
                      <div class="text-body-2">{{ $t('AppBar.SubMenu.Product') }}</div>
                      <v-icon>mdi-chevron-right</v-icon>
                    </a>
                    <router-link to="/ecology/chain" class="d-flex justify-space-between align-center mt-1">
                      <div class="text-body-2">{{ $t('AppBar.SubMenu.Chain') }}</div>
                      <v-icon>mdi-chevron-right</v-icon>
                    </router-link>
                    <a href="https://dapp.supercells.pro/swap" target="_blank" class="d-flex justify-space-between align-center mt-1">
                      <div class="text-body-2">{{ $t('AppBar.SubMenu.Foundation') }}</div>
                      <v-icon>mdi-chevron-right</v-icon>
                    </a>
                    <router-link to="/ecology/scwallet" class="d-flex justify-space-between align-center mt-1">
                      <div class="text-body-2">{{ $t('AppBar.SubMenu.Wallet') }}</div>
                      <v-icon>mdi-chevron-right</v-icon>
                    </router-link>
                    <router-link to="/ecology/community/interaction" class="d-flex justify-space-between align-center mt-1">
                      <div class="text-body-2">{{ $t('AppBar.SubMenu.Comm') }}</div>
                      <v-icon>mdi-chevron-right</v-icon>
                    </router-link>
                  </div>
                </div>

                <router-link to="/road" class="d-flex justify-space-between align-center mt-4">
                  <div class="text-body-1">{{ $t('AppBar.Menu.Roadmap') }}</div>
                  <v-icon>mdi-chevron-right</v-icon>
                </router-link>

                <router-link to="/contact-us" class="d-flex justify-space-between align-center mt-4">
                  <div class="text-body-1">{{ $t('AppBar.Menu.Contact') }}</div>
                  <v-icon>mdi-chevron-right</v-icon>
                </router-link>

                <a href="https://dapp.supercells.pro" class="d-flex justify-space-between align-center mt-4">
                  <div class="text-body-1">DAPP</div>
                  <v-icon>mdi-chevron-right</v-icon>
                </a>

                <a v-if="newsil8n=='zh'" href="https://docs.supercells.vip/cn" target="_blank" class="d-flex justify-space-between align-center mt-4"><div>DOCS</div><v-icon>mdi-chevron-right</v-icon></a>
                <a v-if="newsil8n=='en'" href="https://docs.supercells.vip/en" target="_blank" class="d-flex justify-space-between align-center mt-4"><div>DOCS</div><v-icon>mdi-chevron-right</v-icon></a>
                <a v-if="newsil8n=='ja'" href="https://docs.supercells.vip/jp" target="_blank" class="d-flex justify-space-between align-center mt-4"><div>DOCS</div><v-icon>mdi-chevron-right</v-icon></a>

              </div>
            </div>
          </v-navigation-drawer>

        </div>
      </div>
      
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  methods: {
    selectLanguage(locale) {
      // this.localeLang = locale
      this.$store.commit('setLanguage', locale)
    }
  },
  data: () => ({
    navigationDrawer: false,
    newsil8n:null,
    // localeLang:'ja'
  }),
  mounted(){
    this.newsil8n = this.$i18n.locale
  },
  watch:{
        '$i18n.locale'(newil8n){
        console.log(newil8n);
        this.newsil8n=newil8n;
        }
    },
};
</script>

<style lang="scss">

.v-app-bar {
    color: #AC8250!important;

    a, .link {
      height: 64px;
      line-height: 64px;
      color: #AC8250!important;
      cursor: pointer;
    }
  }

  .nav-menu {
    max-height: auto!important;
  }
  .v-app-bar {
    border-bottom: 1px solid #fff;

    a, .nav-menu-text {
      height: 62px;
      line-height: 62px;
      cursor: pointer;
    }

    .logo-block {
      width: 39px;
      height: 39px;
      background: #AC8250;
      border-radius: 50%;
    }
  }

  .nav-menu-list {
    border-top: 4px solid #AC8250!important;
    border-color: #AC8250;

    a {
      color: #1c1c1c!important;
    }

    .link-item:hover {
      background: rgba(0,0,0,0.05);
    }
  }

  .v-navigation-drawer__content, .v-navigation-drawer__content a {
    color: #221816!important;
  }


  @media screen and (max-width: 960px) {
    .v-app-bar {
      .logo-block {
        width: 30px;
        height: 30px;
        border-radius: 50%;
      }

      .v-navigation-drawer__content a {
        height: 32px;
        line-height: 32px;
      }
    }
  }
</style>
